<template>
  <div>
    <hr />
    <br />
    <br />
    <p>Veuillez vous identifier :</p>

    <p>
      <input
        type="text"
        placeholder="identifiant"
        @change="getLogin($event.target.value)"
      />
    </p>
    <p>
      <input
        type="password"
        placeholder="mot de passe"
        @change="getPassWord($event.target.value)"
      />
    </p>
    <button @click="connect()">se connecter</button>
    <p>inscription à réalisée sur le site : <a href="https://www.themoviedb.org/?language=fr" target="blank">themoviedb</a></p>
    <div v-if="$store.state.erreur" class="erreur">
      identifiant et/ou mot de passe faux <br>
    </div>
  </div>
</template>


<script>
// import { mapActions, mapMutations } from "vuex";
export default {
  name: "Login",
  data() {
    return {};
  },
  components: {},

  methods: {
    // ...mapActions(["getLogin"]),
    // ...mapMutations(["setLogin"]),
    getPassWord: function (value) {
      this.$store.state.password = value;
      console.log(this.$store.state.password);
    },
    getLogin: function (value) {
      this.$store.state.username = value;
      console.log(this.$store.state.username);
    },
    connect: async function () {
      let response = await fetch(
        `${this.$store.state.urlAPI}authentication/token/new?${this.$store.state.apiKey}`
      );
      let json = await response.json();
      this.$store.state.token = json.request_token;
      console.log("request token",this.$store.state.token);

      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.$store.state.username,
          password: this.$store.state.password,
          request_token: this.$store.state.token,
        }),
      };
      try {
        const fetchResponse = await fetch(
          `${this.$store.state.urlAPI}authentication/token/validate_with_login?${this.$store.state.apiKey}`,
          settings
        );
        let infoConnection = await fetchResponse.json();
        console.log("infoconnection", infoConnection);
        if (infoConnection.success === true) {
          this.$router.push({ name: "Accueil" });
        } else {
          this.$store.state.erreur = true;
        }
      } catch (e) {
        return e;
      }
      const settingsSession = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request_token: this.$store.state.token,
        }),
      };
      try {
        const fetchResponseSession = await fetch(
          `${this.$store.state.urlAPI}authentication/session/new?${this.$store.state.apiKey}`,
          settingsSession
        );
       let infoSession = await fetchResponseSession.json();
         this.$store.state.sessionId = infoSession.session_id ;
        console.log("infoSession", this.$store.state.sessionId);
        
      } catch (e) {
        return e;
      }
    },
  },
};
</script>
<style scoped>
p {
  color: white;
}
.erreur {
  color: red;
  font-weight: bold;
  font-size: 20px;
}
</style>